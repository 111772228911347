/**
 * ToDo: Nicer Tabs
 * https://codepen.io/alvarotrigo/pen/VwMJgGO (design)
 * https://codepen.io/alvarotrigo/pen/KKXjJBm (animation)
 */

.controls {
    overflow: hidden;
    border-bottom: 2px solid #333;
    margin: 0 15px 0 5px;
}
@media (prefers-color-scheme: dark) {
    .darkModeDetectionEnabled .controls {
        background: #666;
    }
}
  
.controls input {
    position: relative;
}
.shareDepot .controls input#filterShowTrades,
.shareDepot .controls label {
    display: none;
}
  
  
.controls > div,
.controls select,
.controls label {
    display: inline-block;
    position: relative;
    margin-right: 10px;
    padding: 5px 5px 3px 5px;
}
.controls select {
    max-width: 120px;
    text-overflow: ellipsis;
    background: #333;
    color: white;
    font-weight: bold;
}
.controls > div {
    background: #ddd;
    cursor: pointer;
}
.controls > div.active {
    background: #333;
    color: white;
}
