/* BASICS ************************************************************************/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html, body, #root, .App {
    overflow: hidden;
    scroll-behavior: smooth;
    min-height: 100vh;
    position: relative;
    font-family: Arial, Sans-Serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html {
    overflow-y: auto;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

/*=============== GOOGLE FONTS ===============
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");
*/

:root {
    --header-height: 3rem;

    /*========== Colors ==========*/
    --hue: 174;
    --sat: 63%;
    --first-color: hsl(var(--hue), var(--sat), 40%);
    --first-color-alt: hsl(var(--hue), var(--sat), 36%);
    --title-color: hsl(var(--hue), 12%, 15%);
    --text-color: hsl(var(--hue), 8%, 35%);
    --body-color: hsl(var(--hue), 100%, 99%);
    --container-color: #FFF;

    /*========== Font and typography ==========*/
    --body-font: 'Open Sans', sans-serif;
    --h1-font-size: 1.5rem;
    --normal-font-size: .938rem;
    --tiny-font-size: .625rem;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
}


@media screen and (min-width: 968px) {
    :root {
        --h1-font-size: 2.25rem;
        --normal-font-size: 1rem;
    }
}



/* APP ************************************************************************/
main > * {
    padding: 5px;
    clear: both;
    position: relative;
    width: 100%;
    margin: 20px 0;
}

footer {
    box-shadow: 0 -1px 12px hsla(var(--hue), var(--sat), 15%, 0.15);
    position: fixed;
    right: 0;
    bottom: 0px;
    left: 0;
    padding: 12px;
    font-size: 12pt;
    height: 40px;
    text-align: center;
    background: white;
}

.creditsStyles {
    text-align: center;
    color: #aaa;
}




/* ELEMENTS ************************************************************************/
.hidden {
    opacity: 0;
}


.loaderInfo.failure {
    color: red; 
}
@keyframes spinner {
    to {transform: rotate(360deg);}
}
.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    margin: 100000px;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    margin-left: -30px;
    border-radius: 50%;
    border-top: 4px solid #07d;
    border-right: 2px solid transparent;
    animation: spinner .6s linear infinite;
}
.spinner > * {
    opacity: 40%;
    z-index: -1 !important;
}


.pnlTable th {
    text-align: left;
}
.pnlTable td {
    text-align: right;
}
.pnlTable td.price {
    color: gray;
}
.pnlTable td.diff {
    text-align: left;
}
.pnlTable td.total {
    border-top: 1px solid #333;
    font-weight:bold;
}




/* DARK SCHEME ************************************************************************/
@media (prefers-color-scheme: dark) {
    .App.darkModeDetectionEnabled {
          background: black !important; 
          color: #ddd;
    }
    
    .darkModeDetectionEnabled a {
        color: white;
    }
}




/*=============== HEADER ===============*/
main {
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
}
header {
    position: fixed;
    height: 45px;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--container-color);
    z-index: var(--z-fixed);
    box-shadow: 0 1px 12px hsla(var(--hue), var(--sat), 15%, 0.15);
}

header {
    padding: 3px;
}

h1 {
    position: absolute;
    left: 127px;
    top: 16px;
    font-size: 1em;
    font-weight: normal;
    border-left: 1px solid gray;
    padding-left: 10px;
}


@keyframes asciiLoader {
    0%   {content: ".";}
    50%  {content: "..";}
    100%  {content: "...";}
}
.dots::after {
    content: "";
    animation: asciiLoader 0.4s linear infinite alternate;
}


nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__img {
    width: 32px;
    border-radius: 50%;
}

.nav__logo {
    display: inline-block;
    padding: 7px;
    font-size: 1.5em;
    color: var(--title-color);
    font-weight: 600;
}

.nav__list, 
.nav__link {
    display: flex;
}

.nav__link {
    align-items: center;
    row-gap: 4px;
    color: var(--title-color);
    font-weight: 600;
}

.nav__list {
    justify-content: space-around;
}

.nav__item {
    cursor: pointer;
}

.nav__name {
    font-size: var(--tiny-font-size);
    text-align: center;
}

.nav__icon {
    font-size: 1.5rem;
    font-weight: 100;
}

.active-link {
    position: relative;
    color: var(--first-color);
}

/*=============== MEDIA QUERIES ===============*/
@media screen and (max-width: 767px) {
    header {
        height: 45px;
        box-shadow: 0 -1px 12px hsla(var(--hue), var(--sat), 15%, 0.15);
    }

    nav, .nav__menu {
        position: fixed;
        bottom: 0;
        left: 0;
        position: fixed;
        background-color: var(--container-color);
        box-shadow: 0 -1px 12px hsla(var(--hue), var(--sat), 15%, 0.15);
        width: 100%;
        height: 65px;
        padding: 0 1rem;
        display: grid;
        align-content: center;
    }

    .nav__item {
        width: 100%;
        padding: 5px;
    }

    .nav__link:hover {
        background: #ddd;
    }

    .nav__link {
        flex-direction: column;
        padding: 10px;
    }

    .active-link {
        background: #eee;
    }

    main {
        top: 45px;
        bottom: 65px;
        width: 100%;
    }
}



@media screen and (max-width: 320px) {
  .nav__name {
    display: none;
  }
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .nav__list {
    justify-content: center;
    column-gap: 20px;
  }
}

@media screen and (min-width: 767px) {
    main {
        top: 45px;
        bottom: 40px;
        width: 100%;
    }
  nav {
    height: 30px;
    position: absolute;
    right: 20px;
    top: 9px;
  }
  .nav__img {
    display: none;
  }
  .nav__icon {
    margin-right: 3px;
  }
  .nav__name {
    font-size: var(--normal-font-size);
    /* display: block; */ /* Minimalist design, visible labels */
  }
  .nav__link:hover {
    color: var(--first-color);
  }


  /* Minimalist design */
   .active-link::before{
      bottom: -.75rem;
  } 
}



.shareDepotInfo {
    width: 100%;
    height: 30px;
    margin: 0px;
    padding: 0;
}

.shareDepotInfo > div {
    width: 100%;
    z-index: 1000;
    background: red;
    color: white;
    text-align: center;
    position: fixed;
    height: 30px;
    font-size: clamp(0.7rem, 3.5vw, 1.25rem);
    padding: 0.5vw;
    vertical-align: middle;
}
