.depot_filter {
    height: 35px;
    margin-top: 0;
}

.depot_filter .filter_outer_wrapper {
    margin: -10px 0px 5px 0;
    width: 100%;
    height: 60px;
    padding: 20px 10px 10px 10px;
    text-align: center;
    position: fixed;
    backdrop-filter: blur(2px);
    background: rgba(255, 255, 255, 0.7);
    z-index: 10;
}

.depot_filter .filter_inner_wrapper {
    padding-right: 10px;
}

.depot_filter input {
    background-image: /* filter-icon */ url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAALBJREFUSEvtlUEOwyAMBCc/a17W9mXtz1JtJUdKBLYBofRQLhyCd/BiOwuT1zJZn0sAD+A+mNkTkE4xg21Q3MK/7pQsMkCvfYf4SwAv4Aa8gbXRLot130CaPZBijOdzC6R6NnrIDMQ9EwEiu8ILZAA1iDWkWwxZgCDn/kj1yx9QGzd7f/6sRRolKtEpGagsBdDaZ05tZvVYJC1BJK7dXS0AsyQlfPjrRLcY+d6SQRfnA3goMxlMH255AAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 4px 2px;
    background-size: 20px 20px;
    font-size: 1.0em;
    border: 1px solid #aaa;
    padding: 5px 100px 5px 30px;
    height: 25px;
    width: 100%;
    border-radius: 4px;
    outline: none;
    transition: all 0.5s ease-in-out;
    font-weight: normal;
}
.depot_filter input:not(:placeholder-shown) {
    font-weight: bold;
    background-image: /* filled-filter-icon */ url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAASZJREFUaEPtmEESwiAMRX9vpidTT6Y308mMLqyFJuQH6Bg2XZW+l08Jw4KDj+Xg/EiB0QlmAjMncAJwASDPkeMB4AZAnj+jtoTuE8B/gAX+bBV4jiy7tti1BFKAnOBmsTMBcpVr0/1fAjNto9IHrtZtVBqYSMwwpAeYG5mAi7V045GjWH2B0hzmRkpU4bUCo5LYhbcI9JZQwVsFekmo4VsEoiVM8K0CURJmeI8AW6IJ3ivAkmiGZwh4JVzwLIFWCTc8U8AqQYFnC2glaPARAnsSVPgogZIEHT5SQOZe32poTr7mY3vIpG+KFNDEkQlUqpRLKJeQpgK5hJxV6rULhXThXp04DL6HQCh8tIDc6G1eyDqX/dfrkf8Ak7M4Vwp0KXPlI4dP4AVSsj0xANLWeQAAAABJRU5ErkJggg==)
}
.depot_filter input:placeholder-shown ~ .filterResult {
    display: none;
}
.depot_filter input:focus{
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

.depot_filter .filterResult {
    position: absolute;
    right: 27px;
    top: 26px;
    font-size: 0.8em;
    color: #888;
}
.depot_filter .filterResult::before {
    content: attr(data-match) '/' attr(data-total) ' matching';
}
.depot_filter .filterResult[data-match='0']::before {
    color: red;
}

/*
.tag {
    display: inline-block;
    color: white;
    float: left;
    margin: 5px 5px 5px 0;
    padding:  2px;
    background: grey;
    border-radius: 5px;
}
  
.tag:hover {
    background: darkgrey;
    cursor: pointer;
}
*/
