/* DEPOT TABLE */
.depot {
    position: relative;
    border-top: 1px solid grey;
    font-size:12px;
    border-collapse:collapse;
    width: 100%;
}
  
.depot th,
.depot td {
    width: 1%;
    overflow: hidden;
    white-space: nowrap;
}
    
.depot th {
    text-align: right;
    padding:1px;
}
  
.depot thead th {
    border-bottom: 1px solid #aaa;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
  
.depot tfoot th {
    border-top: 1px solid #aaa;
}
  
.depot td {
    border-collapse: collapse;
    padding: 1px 3px;
    text-align: right;
}
  
.depot .name
,.depot .note
,.depot .market
,.depot .type {
    text-align: left;
}
  
.depot th.name
,.depot td.name
,.depot th.note
,.depot td.note {
    width: auto;
    max-width: 0;
    cursor: pointer;  
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
  
.depot tbody tr:hover td {
    background-color: #dcdefd !important;
}


.depot tbody tr:nth-child(odd){
    background-color: #EEE;
}

.depot tbody tr:nth-child(even) > .sortcolumn {background-color: #F4F4F4;} 
.depot tbody tr:nth-child(odd) > .sortcolumn {background-color: #DDD;}
 
.depot thead th.sortcolumn {
    background-image: linear-gradient(#DDD, #F4F4F4, #DDD);
    background-size: auto 200%;
    background-position: 0 100%;
    transition: background-position 0.3s;
}
.depot thead th.sortcolumn.priceDiffPer,
.depot thead th.sortcolumn.valueDiffAbsToday,
.depot thead th.sortcolumn.valueDiffPer,
.depot thead th.sortcolumn.valueDiffAbs {
    background-image: linear-gradient(rgb(207, 253, 207), rgb(255, 186, 186), rgb(207, 253, 207));
}

.depot thead th.sortcolumn.isSortedDesc {
    background-position: 100% 0;
}
  


  
.depot tr > td.buyDate,
.depot tr > td.price,
.depot tr > td.type {
    border-left: 1px solid #aaa;
}
.depot tr td.iterator,
.depot tr td.name,
.depot tr td.buyValue,
.depot tr td.valueDiffAbsToday {
    border-right: 1px solid #aaa;
}

.depot tr .priceDiffPer {
    font-weight: bold;
} 
    
.depot tr.isDataOld .price span,
.depot tr.isDataOld .priceDiffPer span,
.depot tr.isDataOld .valueDiffAbsToday span {
    opacity: 0.7;
    font-weight: normal;
}
  
.depot tr.isDataFromYesterday .price span,
.depot tr.isDataFromYesterday .priceDiffPer span,
.depot tr.isDataFromYesterday .valueDiffAbsToday span {
    opacity: 0.3;
    font-weight: normal;
}
.depot th.header {cursor: ns-resize;}
.depot th.headerSortDown {cursor: n-resize;}
.depot th.headerSortUp {cursor: s-resize;}
  
  
@media (prefers-color-scheme: dark) {
    .darkModeDetectionEnabled .depot tr.odd {background-color: #222;}
    .darkModeDetectionEnabled .depot tr:hover td {background-color: #333 !important;}
    .darkModeDetectionEnabled .depot tbody tr.even > .sortcolumn {background-color: #222;}
    .darkModeDetectionEnabled .depot tbody tr.odd > .sortcolumn,
    .darkModeDetectionEnabled .depot thead tr > th.sortcolumn {background-color: #333;}
}

/* responsive */
@media only screen and (max-width: 1200px) {
    .depot tr .note,
    .depot tr .limitBottom,
    .depot tr .limitTop,
    .menu-tooltip .deleteLink {
        display:none; visibility: hidden;
    }
  
    .chartContainer {
        width: 100% !important;
        float: none;
    }
}
  
@media only screen and (max-width: 900px) {
    .depot tr .iterator,
    .depot tr .type,
    .depot tr .market {
      display:none; visibility:hidden;
    }
  
    /*
    .depot th.name {
        padding-left: 25px;
    }
    */       
}
  
@media only screen and (max-width: 600px) {
    .depot tr .buyDate,
    .depot tr .count {
        display: none;
        visibility: hidden;
    }
    .google-visualization-tooltip {
        font-size: 9pt !important;
    }
  
    .chartContainer img {
        width: 100%;    
    } 
}
  
@media only screen and (max-width: 500px) {
    .depot tr .buyPrice,
    .depot tr .buyValue {
        display: none;
        visibility: hidden;
    }
}


tr.watchlist td.buyDate,
tr.watchlist td.buyPrice,
tr.watchlist td.count,
tr.watchlist td.buyValue,
tr.watchlist td.valueDiffAbsToday,
tr.watchlist td.valueDiffPer,
tr.watchlist td.valueDiffAbs,
tr.watchlist td.value {
    font-size: 0;
}


/* https://stackoverflow.com/questions/35862715/create-html-table-with-increment-number-of-column */
table {
    counter-reset: rowNumber;
}
tr:not(.watchlist) td.iterator::before {  
    counter-increment: rowNumber;
    content: counter(rowNumber);
}




.shareDepotLabel {
    background: red;
    font-weight: bold;
    color: white;
}
div.shareDepotLabel {
    text-align: center;
    padding: 4px;
}
  
  
  

  

.alert, .alertError {
    animation: blinker 1s linear infinite;
    background-color: #fff2ac;
}
.alertError {
    background-color: #fc7c7c;
}
@keyframes blinker {
    50% {
        opacity: 0.4;
    }
}
  
@media (prefers-color-scheme: dark) {
    .darkModeDetectionEnabled .alert {
        background-color: #666600;
    }
    .darkModeDetectionEnabled .alertError {
        background-color: #ffcfcf;
    }
}
